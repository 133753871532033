
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface UsuarioIpirangaFilter {
  nome: string;
  email: string;
  regiao: string;
  cargo: string;
}

export default defineComponent({
  name: "FilterIpirangaUsers",
  components: {
    FilterBase,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();

    const closeModal = ref(false);
    const arrayRegioes: Ref<any[]> = ref([]);
    const arrayCargos: Ref<any[]> = ref([]);

    const usuarioIpirangaFilter: Ref<UsuarioIpirangaFilter> = ref({
      nome: "",
      email: "",
      regiao: "",
      cargo: "",
    });

    async function getRegioes() {
      const { data } = await Api.get("getRegioesUsuariosIpiranga");
      return data
    }

    async function getCargos() {
      const { data } = await Api.get("getCargosUsuariosIpiranga");
      return data
    }

    function enviarEmit() {
      emitter.emit("Filter-UsuariosIpiranga", usuarioIpirangaFilter.value );
      closeModal.value = !closeModal.value;
    }

    // watch(
    //   ()=> usuarioIpirangaFilter.value,
    //   ()=> console.log('userIpirangaFilter', usuarioIpirangaFilter.value),
    //   {deep: true}
    // )


    onMounted(() => {
      Promise.all([getRegioes(), getCargos()]).then((values) => {
        arrayRegioes.value = values[0];
        arrayCargos.value = values[1];
      });
    });

    return { usuarioIpirangaFilter, arrayRegioes, arrayCargos, enviarEmit, closeModal, ptBr };
  },
});
